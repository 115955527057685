












































import { CreatedCredential, GetCredentialsRes } from "@/includes/types/Credentials";

import EmptyData from "piramis-base-components/src/components/EmptyData/EmptyData.vue";

import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { snakeCase } from "lodash";

@Component({
  methods: {
    snakeCase
  },
  components: {
    EmptyData
  }
})
export default class CredentialsList extends Vue {
  @Prop() credentials!: GetCredentialsRes['credentials']

  @Emit()
  selectCredential(credential: CreatedCredential) {
    return credential
  }

  @Emit()
  removeCredential(key: CreatedCredential['key']) {
    return key
  }
}
