





























































import { SelectedCredential } from "./types";
import { CredentialsLogic } from "@/includes/logic/Credentials/CredentialsLogic";

import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue';
import TextInput from 'piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue';
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import { SelectOption } from "piramis-base-components/src/logic/types";

import { Component, Prop, Mixins, VModel } from 'vue-property-decorator';
import { snakeCase } from "lodash";

@Component({
  components: {
    TextInput,
    SelectInput
  }
})
export default class CredentialSetting extends Mixins(ModelSetter) {
  @VModel() credential!: SelectedCredential

  @Prop() providers!: Array<string>

  @Prop({ required: true }) credentialBuilders!: CredentialsLogic['keyedBuilders']

  updateSettingsOpen = false

  get provider() {
    return this.credential?.provider ?? ''
  }

  set provider(provider: string) {
    if (!this.credential?.key) {
      this.credential.provider = provider

      const builder = this.credentialBuilders[provider]

      if (builder) {
        this.credential.params = builder.getParams()
      }
    }
  }

  get isCreatedCredential() {
    return !!this.credential?.key
  }

  async providerOptions(): Promise<Array<SelectOption>> {
    return this.providers.map(p => {
      return {
        label: this.$t(`${ snakeCase(p) }`).toString(),
        value: p
      }
    })
  }

  toggleParams() {
    this.updateSettingsOpen = !this.updateSettingsOpen

    if (this.updateSettingsOpen) {
      const builder = this.credentialBuilders[this.credential.provider]

      if (builder) {
        this.credential.params = builder.getParams()
      }
    } else {
      this.credential.params = undefined
    }
  }
}
