






















import CredentialsContainer from "@/components/Credentials/CredentialsContainer.vue";
import { chatkeeperCredentials } from "@/includes/logic/Credentials/ChatkeeperCredentials";

import CenteredColumnLayout from "piramis-base-components/src/components/CenteredColumnLayout.vue";
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue';

import { Vue, Component, Watch } from 'vue-property-decorator';

@Component({
  data() {
    return {
      chatkeeperCredentials
    }
  },
  components: {
    CredentialsContainer,
    PageTitle,
    CenteredColumnLayout
  }
})
export default class ChatCredentials extends Vue {
  settingModalOpen = false

  updateKey = 0

  @Watch('$route.params.CHAT_ID')
  onChatIdChange() {
    this.updateKey += 1
  }
}
