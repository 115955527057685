var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"credential-setting"},[(!_vm.isCreatedCredential)?_c('select-input',{staticClass:"mt-0",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': this,
        'key': 'provider',
        'prefix': 'credentials_',
        'options': _vm.providerOptions,
        'multiple': false,
        'validation': 'required',
        'clearable': false,
        supportButtons: false
      },
    }}}):_vm._e(),_c('text-input',{class:{
      'mt-0': _vm.isCreatedCredential,
    },attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.credential,
        'key': 'title',
        'prefix': 'credentials_',
        'validation': 'required'
      },
    }}}),_c('div',{staticClass:"text-center mt-5",class:{
      'mb-5': _vm.updateSettingsOpen
    }},[(_vm.isCreatedCredential)?_c('a-button',{class:{
        'active': _vm.updateSettingsOpen
      },on:{"click":_vm.toggleParams}},[_vm._v(" "+_vm._s(_vm.$t('credentials_set_new_params_button_title'))+" ")]):_vm._e()],1),(!_vm.isCreatedCredential || _vm.updateSettingsOpen)?[_c(_vm.credentialBuilders[_vm.credential.provider].setupView,{tag:"component",model:{value:(_vm.credential.params),callback:function ($$v) {_vm.$set(_vm.credential, "params", $$v)},expression:"credential.params"}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }