import { render, staticRenderFns } from "./chat-credentials.vue?vue&type=template&id=638d7464&scoped=true&"
import script from "./chat-credentials.vue?vue&type=script&lang=ts&"
export * from "./chat-credentials.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "638d7464",
  null
  
)

export default component.exports