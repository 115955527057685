































import { SelectedCredential } from "./types";
import CredentialsList from "./CredentialsList.vue";
import CredentialSetting from "./CredentialSetting.vue";
import { errorNotification, successNotification } from "@/includes/NotificationService";
import { CredentialsLogic } from "@/includes/logic/Credentials/CredentialsLogic";
import {
  CreateCredentialReq,
  CreatedCredential,
  GetCredentialsRes,
  UpdateCredentialReq
} from "@/includes/types/Credentials";

import { Vue, Component, PropSync, Watch, Prop } from 'vue-property-decorator';

@Component({
  components: {
    CredentialSetting,
    CredentialsList
  }
})
export default class CredentialsContainer extends Vue {
  @PropSync('modalOpen', { type: Boolean, default: false, required: true }) isModalOpen!: boolean

  @Prop({ required: true }) targetId!: number | string

  @Prop({ required: true }) credentialsLogic!: CredentialsLogic

  credentialsInfo: GetCredentialsRes | null = null

  currentCredential: SelectedCredential | null = null

  @Watch('isModalOpen')
  onModalChange(state: boolean) {
    if (state && !this.currentCredential) {
      const [ provider ] = this.credentialsInfo?.providers ?? []
      const builder = this.credentialsLogic.keyedBuilders[provider]

      if (builder) {
        this.currentCredential = {
          title: '',
          provider,
          params: builder.getParams(),
        }
      }
    }
  }

  get credentialsModalTitle() {
    if (this.currentCredential?.key) {
      return this.$t('credentials_edit_modal_title')
    }

    return this.$t('credentials_create_modal_title')
  }

  selectCredential({ title, key, provider }: CreatedCredential) {
    this.currentCredential = {
      title,
      key,
      provider,
      params: undefined
    }

    this.isModalOpen = true
  }

  removeCredentialWarn(key: CreatedCredential['key']) {
    this.$confirm({
      title: this.$t("credentials_confirm_remove_item_title").toString(),
      content: this.$t("credentials_confirm_remove_item_content").toString(),
      okText: this.$t('pi_accept').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk: () => {
        this.removeCredential(key)
      }
    })
  }

  removeCredential(key: CreatedCredential['key']) {
    this.credentialsLogic.deleteCredentialApi({ key })
      .then(res => {
        if (this.credentialsInfo) {
          this.credentialsInfo.credentials = this.credentialsInfo.credentials.filter(c => c.key !== key)

          successNotification()
        }
      })

  }

  updateCredentials(payload: UpdateCredentialReq) {
    this.credentialsLogic.updateCredentialsApi(payload)
      .then(({ data }) => {
        if (this.credentialsInfo) {
          const idx = this.credentialsInfo.credentials.findIndex(c => c.key === payload.key)

          if (idx !== -1){
            this.credentialsInfo.credentials.splice(idx, 1, data)

            successNotification()
          }

          this.isModalOpen = false
        }
      })
      .catch(errorNotification)
  }

  createCredential(payload: CreateCredentialReq) {
    this.credentialsLogic.createCredentialApi(payload)
      .then(({ data }) => {
        if (this.credentialsInfo) {
          this.credentialsInfo.credentials.push(data)

          this.isModalOpen = false

          successNotification()
        }
      })
      .catch(errorNotification)
  }

  saveCredential() {
    if (this.currentCredential) {
      const { key, title, provider, params } = this.currentCredential
      const target_id = this.targetId

      if (params) {
        params.validate()
          .then(res => {
            if (key) {
              this.updateCredentials({ key, title, params, target_id })
            } else {
              this.createCredential({ params, title, provider, target_id })
            }
          })
          .catch(errorNotification)
      } else if (key) {
        this.updateCredentials({ key, title, target_id })
      }
    }
  }

  afterModalClose() {
    this.currentCredential = null
  }

  created() {
    this.credentialsLogic.getCredentialsApi({ target_id: this.targetId })
      .then(({ data }) => {
        this.credentialsInfo = data
      })
      .catch((error) => {
        errorNotification(error)

        this.credentialsInfo = {
          credentials: [],
          providers: []
        }
      })
  }
}
