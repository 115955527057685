import { render, staticRenderFns } from "./CredentialsList.vue?vue&type=template&id=b4a4a054&scoped=true&"
import script from "./CredentialsList.vue?vue&type=script&lang=ts&"
export * from "./CredentialsList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4a4a054",
  null
  
)

export default component.exports